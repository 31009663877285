 <template>
  <div class="text-center">
         <v-dialog v-model="dialog" persistent :max-width="options.width" :style="{ zIndex: options.zIndex }">
            <v-overlay :absolute="true" :value="loadingModal">
                <v-progress-circular :size="40" color="amber" indeterminate></v-progress-circular>
            </v-overlay>

              <div :class="`card zn-card card-custom wave wave-animate-slow wave-${color} mb-8 mb-lg-0`">
                    <div class="card-body">
                         <!-- <v-btn @click="dialog = false" icon medium :color="color" style="position: absolute;right: 6px;">
                            <v-icon style="font-size: 25px !important;">mdi-close-circle-outline</v-icon>
                        </v-btn> -->
                        <div class="p-7">
                           <!-- <div class="mr-6">
                               <i :class="`la la-check-circle zn-notif-icon text-`+color" style="top: 20px;position: absolute;left: 15px;"></i>
                            </div> -->
                            <div class="d-flex flex-column text-center">
                                <div :class="'font-size-h4 mb-0 text-'+color" style="font-weight: 500;">
                                    {{title}}
                                </div>
                                <div :class="'mt-2 text-'+color" style="font-weight: 100;">
                                  {{message}}
                                </div>
                                <div class="mt-7 text-center">
                                  <v-spacer></v-spacer>
                                  <v-btn color="success" rounded text @click="dialog = false">
                                      Tidak
                                  </v-btn>
                                  <v-btn color="success" rounded depressed @click.native="agree">
                                      Ya
                                  </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
         </v-dialog>
     </div>
</template>

<script>
export default {
    name: "ConfirmDlg",
    data() {
      return {
        loadingModal:false,
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        color:'success',
        title: null,
         options: {
          color: "success",
          width: 300,
          zIndex: 200,
          noconfirm: false,
        },
      };
    },

    methods: {
      open(title, message, options) {
        this.dialog = true;
        this.title = title;
        this.message = message;
        this.options = Object.assign(this.options, options);
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      agree() {
        this.resolve(true);
        // this.dialog = false;
      },
      cancel() {
        this.resolve(false);
        this.dialog = false;
      },
      close() {
            this.dialog = false;
        },
       loading() {
            this.loadingModal = true;
        },
        loadingEnd() {
            this.loadingModal = false;
        },
    },
  };

// export default {
//     props: ['show', 'judul'],
//     name: 'dialogConfirm',
// }
</script>

